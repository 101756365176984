import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Accordion from 'react-tiny-accordion';

import GatsbyImage from 'components/common/GatsbyImage';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';
import useScreenRecognition from 'hooks/useScreenRecognition';
import BannerCard from './BannerCard/BannerCard';

import { SymptomsCampaignBannerCardProps } from './models';
import './SymptomsCampaignBanner.scss';
import './ShadowSymptomsCampaignBanner.scss';

const SymptomsCampaignBanner: FC<SymptomsCampaignBannerCardProps> = ({
  banner: {
    properties: { content, title, cards },
  },
}) => {
  const {
    properties: { number, subTitle, titleUnder, title: bannerTitle, logo },
  } = title[0];

  const { isDesktop } = useScreenRecognition();
  return (
    <div data-testid="SymptomsCampaignBanner" className="symptoms-campaign-banner">
      <div className="symptoms-campaign-banner__top-block">
        <div className="symptoms-campaign-banner__white-line" />
        <div className="symptoms-campaign-banner__pink-line" />
        <div className="symptoms-campaign-banner__teal-line" />
        <div className="symptoms-campaign-banner__text-content">
          {logo?.[0].properties?.image?.fallbackUrl ? (
            <div className="symptoms-campaign-banner__text-content-logo">
              <GatsbyImage
                fluid={logo[0].properties.image}
                alt={logo[0].properties.imageAlt}
                className="symptoms-campaign-banner__text-content-logo-img"
              />
            </div>
          ) : (
            <div className="symptoms-campaign-banner__text-content-title">
              <div className="symptoms-campaign-banner__title">{bannerTitle}</div>
              <div className="symptoms-campaign-banner__title-under">{titleUnder}</div>
              <div className="symptoms-campaign-banner__title-number">{number}</div>
              <div className="symptoms-campaign-banner__title-sub-title">{subTitle}</div>
            </div>
          )}
          <div className="symptoms-campaign-banner__text-content-text">{content}</div>
        </div>
        <div className="symptoms-campaign-banner__teal-line--right" />
        <div className="symptoms-campaign-banner__light-blue-line--right" />
      </div>
      <div className="symptoms-campaign-banner__bottom-block">
        {cards.map(({ properties: { title: cardTitle, subTitle: cardSubTitle, ...rest } }) =>
          isDesktop ? (
            <BannerCard
              key={cardTitle}
              card={{ title: cardTitle, subTitle: cardSubTitle, ...rest }}
              isDesktop={isDesktop}
            />
          ) : (
            <Accordion
              key={cardTitle}
              className="symptoms-campaign-banner__accordion"
              transitionDuration="300"
            >
              <div
                className="symptoms-campaign-banner__accordion__item"
                data-header={
                  <Button variant="icon" classes="symptoms-campaign-banner__accordion__button">
                    <IconCustom icon="chevron-down" />
                    <div className="symptoms-campaign-banner__accordion__button-block">
                      <div className="symptoms-campaign-banner__accordion__title">{cardTitle}</div>
                      <div className="symptoms-campaign-banner__accordion__sub-title">
                        {cardSubTitle}
                      </div>
                    </div>
                  </Button>
                }
              >
                <BannerCard card={{ title: cardTitle, ...rest }} isDesktop={isDesktop} />
              </div>
            </Accordion>
          )
        )}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentSymptomsCampaignBanner on TSymptomsCampaignBanner {
    properties {
      content
      cards {
        ...FragmentBannerCard
      }
      image {
        properties {
          image {
            ...FragmentGatsbyImage
          }
          imageAlt
        }
      }
      title {
        properties {
          titleUnder
          number
          numberTitle
          subTitle
          title
          logo {
            properties {
              image {
                ...FragmentGatsbyImage
              }
              imageAlt
            }
          }
        }
      }
    }
  }
`;

export default SymptomsCampaignBanner;
