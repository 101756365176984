import React from 'react';

import DiscoveryTextSection from 'components/DiscoveryTextSection';

const bodyItems = {
  'Discovery Section': ({ properties }, keyId) => (
    <DiscoveryTextSection key={keyId} {...properties} />
  ),
};

export default bodyItems;
